$swatch-sizes: (
    default: (
        swatch-size: rem-calc(24),
        border-size: rem-calc(2)
    ),
    small: (
        swatch-size: rem-calc(19),
        border-size: rem-calc(0)
    ),
    medium: (
        swatch-size: rem-calc(36),
        border-size: rem-calc(4)
    ),
    large: (
        swatch-size: rem-calc(35),
        border-size: rem-calc(0)
    ),
    x-large: (
        swatch-size: rem-calc(56),
        border-size: rem-calc(0)
    )
);

$swatch-padding: 0;
$swatch-radius: 0;
$swatch-radius--large: $swatch-radius;
$swatch-border-width: rem-calc(1);
$swatch-selected-border-color: color(primary);
$swatch-selected-border-padding: $swatch-border-width solid color(element-background);
$swatch-disabled-icon: swatch-cross;
$swatch-disabled-icon-color: color(light);
$swatch-selected-box-shadow: inset rem-calc(0 0 0 4) color(light);

@import "@lora/05-components/swatch";