$search-refinements-border-top: none;

// Search refinements header
$search-refinements-header-title-font: 500 #{rem-calc(14)} / rem-calc(20) $font-secondary;
$search-refinements-header-title-font--large: $search-refinements-header-title-font;
$search-refinements-header-title-margin--large: 0;
$search-refinements-header-title-padding--large: rem-calc(5 16 0 0);
$search-refinements-header-border-top--large: rem-calc(1) solid color(border);
$search-refinements-header-padding: rem-calc(0 12);
$search-refinements-header-padding--large: rem-calc(16 0);
$search-refinements-header-back-and-results-font: 450 #{rem-calc(14)} / rem-calc(17) $font-primary;

// Category Name
$search-refinements-category-name-border-top: none;
$search-refinements-category-name-padding: rem-calc(16 0);
$search-refinements-category-name-margin: rem-calc(0 0 18);
$search-refinements-category-name-font: 500 #{rem-calc(12)} / rem-calc(18) $font-secondary;
$search-refinements-footer-margin: rem-calc(0 0 20);
$search-refinements-footer-padding: rem-calc(8 6);

$search-refinements-header-reset-padding: rem-calc(20 24 15);

// Refinements in a Sliding panel styles
$search-refinements-sliding-panel-header-padding--large: rem-calc(16);

@import "@lora/05-components/search/search-refinements";