// Search refinement content
$search-refinement-content-background--medium-down: color(secondary);
$search-refinement-content-padding--large: rem-calc(16);
$search-refinement-content-padding--medium-down: $search-refinement-content-padding--large;

// Search refinement title
$search-refinement-title-font: 500 #{rem-calc(12)} / rem-calc(18) $font-secondary;
$search-refinement-title-font--large: $search-refinement-title-font;
$search-refinement-title-padding: rem-calc(16 12);
$search-refinement-title-padding--large: rem-calc(27 16 23);
$search-refinement-title-expanded-padding--large: rem-calc(9 16);
$search-refinement-title-expanded-font: 700 #{rem-calc(14)} / rem-calc(17) $font-secondary;
$search-refinement-title-expanded-color: color(dark);

$search-refinement-clear-font: normal #{rem-calc(14)} / rem-calc(20) $font-primary;
$search-refinement-title-expanded-border-left: rem-calc(2) solid color(primary-active);
$search-refinement-title-expanded-border-left--large: rem-calc(4) solid color(primary-active);
$search-refinement-title-expanded-border-left--large: none;
$search-refinement-title-text-text-transform: none;

$search-refinement-content-wrapper-background-color: color(global-background);
$search-refinement-applied-values-font: 450 #{rem-calc(12)} / rem-calc(14) $font-primary;
$search-refinement-applied-values-color: color(text-secondary);

@import "@lora/05-components/search/search-refinement";