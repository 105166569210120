$search-plp-top-banner-text-align: left;
$search-plp-top-banner-background: color(dark);
$search-plp-top-banner-category-cover-display: block;

// Category name
$search-plp-top-banner-category-name-font: 500 #{rem-calc(14)} / 1.42 $font-secondary;
$search-plp-top-banner-category-name-margin: rem-calc(17 0 2 15);
$search-plp-top-banner-category-name-text-transform: none;
$search-plp-top-banner-category-name-display: inline-block;
$search-plp-top-banner-category-name-with-category-cover-display: inline-block;

// Results count
$search-plp-top-banner-results-count-font: $search-plp-top-banner-category-name-font;
$search-plp-top-banner-results-count-display: inline-block;

@import "@lora/05-components/search/search-plp-top-banner";